import _isArray from 'lodash/isArray';
import _mapValues from 'lodash/mapValues';
import _omit from 'lodash/omit';
import queryString from 'query-string';
import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';

import EvTruck from '@magnus/react-native-evtruck';
import Mutator from '@magnus/react-native-mutator';
import DeviceProps from '@magnus/react-native-device-props';

import Session from '@wowmaking/react-native-analytics/core/helpers/session';

import * as AppyCNT from './appycnt';

declare global {
  interface Window {
    dataLayer?: Record<string, any>[];
  }
}

const isGRA = window.navigator?.userAgent?.includes?.('Google-Read-Aloud');

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENV,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0.1,
});

const Analytics = {
  init(magnusToken: string, appVersion: string) {
    if (isGRA) {
      return Promise.resolve();
    }

    DeviceProps.getIDFM()
      .then(idfm => {
        Sentry.setUser({ id: idfm });

        window.dataLayer?.push({
          idfm,
        });
      });

    DeviceProps.setAppVersion(appVersion);

    EvTruck.init({
      token: magnusToken,
    });

    return Session.init(this).then(() => {
      const p = queryString.parse(window.location.search);

      let partition = window.location.pathname.split('/')[1];

      Mutator.setAttribution({ source: String(p.utm_source || ''), campaign_id: String(p.campaign_id || ''), });

      this.setUserProperty('testmode', p.testmode);
      this.setUserProperty('mode', p.mode);
      this.setUserProperty('summary', p.summary);

      this.setUserProperty('domain', window.location.hostname);
      this.setUserProperty('partition', partition);

      if (p.utm_source) {
        this.setUserProperty('utm_source', _isArray(p.utm_source) ? p.utm_source[0] : p.utm_source);
      }

      if (p.campaign_id) {
        this.setUserProperty('campaign_id', _isArray(p.campaign_id) ? p.campaign_id[0] : p.campaign_id);
      }

      this.trackEvent('session', 'url_params',
        _mapValues(
          _omit(p, ['utm_source', 'campaign_name', 'campaign_id', 'adset_name', 'adset_id', 'ad_name', 'ad_id']),
          String
        )
      );

      if (p.reset !== undefined) {
        setInterval(() => {
          window.localStorage.clear();
        }, 10);
        setTimeout(() => {
          delete p.reset;
          window.location.href = queryString.stringifyUrl({ url: window.location.origin + window.location.pathname, query: p });
        }, 1000);
      }

      window.addEventListener('beforeunload', () => {
        this.trackEvent('session', 'end');
      });

      return p;
    });
  },

  setUserProperty(name, value) {
    EvTruck.setUserProperty(name, value);
  },

  trackEvent(category: string, action: string, params?: object) {
    console.log('trackEvent:', category, action, params);
    const event = `${category}_${action}`;
    EvTruck.trackEvent(event, params);
    // window.dataLayer?.push({ event, ...params }); // GTM
    window.gtag && window.gtag('event', event, params);
  },

  trackPurchaseEvent(transaction) {
    EvTruck.trackEvent('ecommerce_purchase', {
      transactionId: transaction.transactionId,
      productId: transaction.productId,
      value: transaction.revenue,
      currency: transaction.currency,
      method: transaction.method,
      paymentSystem: transaction.paymentSystem,
    });

    // window.dataLayer?.push({
    //   event: 'purchase',
    //   currency: transaction.currency,
    //   value: Number(transaction.revenue),
    //   transaction_id: transaction.transactionId,
    //   product_id: transaction.productId,
    //   method: transaction.method,
    // });
    window.gtag && window.gtag('event', 'purchase', {
      currency: transaction.currency,
      value: Number(transaction.revenue),
      transaction_id: transaction.transactionId,
      product_id: transaction.productId,
      method: transaction.method,
    });

    AppyCNT.trackPurchaseEvent(transaction);
  },

  getSessionNumber() {
    return Session.getNumber();
  },

  flush() {
    EvTruck.flush();
  },
};

export default Analytics;
